@font-face {
    font-family: "Gotham Book";
    src: url('/fonts/Gotham-Book.tff') format('ttf');
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Gotham Medium";
    src: url('/fonts/Gotham-Medium.ttf') format('ttf');
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: "Gotham BookItalic";
    src: url('/fonts/Gotham-BookItalic.ttf') format('ttf');
    font-style: italic;
    font-weight: 400;
  } 